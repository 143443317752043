export default {
    "error.general_title": "Erro",
    "error.general_description": "Erro interno, por favor volte a tentar mais tarde.",
    "error.general_failed_loading_data": "Erro ao obter dados",
    "error.general_failed_upload_file": "Upload de ficheiro '{file}' falhou: {msg}",
    "error.general_cancel": "Cancelar",
    "error.general_delete": "Apagar",
    "success.general_title": "Successo",
    "success.image_caption_updated": "Image caption updated",
    "root.close": "Fechar",
    "root.organization": "Agency Name",
    "root.required": "Campo obrigatório",
    "root.onlynumbers": "Apenas números.",
    "root.organization_size": "Please enter at least 3 characters.",
    "root.organization_max": "The max size is 255.",
    "root.firstname": "Primeiro Nome",
    "root.lastname": "Último Nome",
    "root.email": "Email",
    "root.email_valid": "O Email não é válido",
    "root.phone": "Telefone",
    "root.invalid.phone": "Número de telefone não é válido",
    "root.invalid.postal.code": "Código postal não é válido",
    "root.brands": "Marcas",
    "root.choose_password": "Escolha uma password",
    "root.choose_password_size": "A password tem de ter pelo menos 8 caracteres",
    "root.choose_password_max": "O tamanho máximo é 255",
    "root.password": "Password",
    "root.confirm_password": "Repetir password",
    "root.confirm_password_match": "As passwords têm de ser iguais.",
    "root.password_changed": "Password alterada.",
    "root.cancel": "Cancelar",
    "root.create_account": "Criar conta",
    "root.something_weng_wrong": "Algo correu mal...",
    "root.invited_as_user": "Criar conta",
    "root.already_have_account": "Já tem conta? ",
    "root.return_to_login": "Voltar ao log in",
    "root.trial_period_ends": "Trial period ends on",
    "root.last_subscription_period_end": "Last subscription period ends on",
    "root.subscription_ended": "Your subscription ended on",
    "root.manage_your_subscriptions": "Billing",
    "root.profile": "Perfil",
    "root.change_password": "Alterar password",
    "root.profile_image": "Profile image",
    "root.upload_new_image": "Upload new image",
    "root.choose_file": "Choose File",
    "root.remove_image": "Remove Image",
    "root.save": "Guardar",
    "root.profile_updated": "Perfil actualizado",
    "root.image_removed": "Image removed",
    "root.only_images_allowed": "Only image files allowed",
    "root.api_not_supported": "Sorry, FileReader API not supported",
    "root.file_uploaded": "File uploaded",
    "root.saved": "Guardado",
    "root.back": "Voltar",
    "root.generic_max_size": "O tamanho máximo é {max}",
    "root.generic_min_size": "O tamanho mínimo é {min}",
    
    "UserInvite.title": "Adicionar utilizador",
    "UserInvite.fields.email": "Email",
    "UserInvite.fields.email_required": "Campo obrigatório",
    "UserInvite.fields.email_invalid": "O Email não é válido",
    "UserInvite.fields.user_type": "Tipo",
    "UserInvite.fields.user_type_required": "Campo obrigatório",
    "UserInvite.fields.user_brands": "Marcas",
    "UserInvite.fields.user_brands_required": "Campo obrigatório",
    "UserInvite.button.send_invite": "Enviar convite",
    "UserInvite.button.go_back": "Voltar",
    "UserInvite.messages.failed_loading_roles": "Erro ao carregar perfis de utilizador",
    "UserInvite.messages.invite_sent": "Convite enviado por email",
    "UserList.title": "Utilizadores",
    "UserList.invites_title": "Convites enviados",
    "UserList.button.invite_user": "Adicionar utilizador",
    "UserList.table.active": "Ativo",
    "UserList.table.name": "Nome",
    "UserList.table.role": "Tipo",
    "UserList.table.email": "Email",
    "UserList.table.brands": "Marcas",
    "UserList.table.type": "Tipo",
    "UserList.table.sent_by": "Enviado por",
    "UserList.table.date": "Data",
    "UserList.messages.delete_user": "Apagar utilizador",
    "UserList.messages.change_status_user": "Alterar Estado Utilizador",
    "UserList.messages.activate_status_user": "Activar",
    "UserList.messages.deactivate_status_user": "Desactivar",
    "UserList.messages.invites_table_empty": "Lista de convites vazia",
    "UserList.messages.delete_invite": "Apagar convite",

    // main menu Sidebar
    "mainmenu.home": "Home",
    "mainmenu.configuration": "Configuração",
    "mainmenu.users": "Utilizadores",
    "mainmenu.about": "Sobre",


}
