import Vue from "vue";
import "./registerServiceWorker";
import router from "./router/router";
import store from "./store/store";
import VueI18n from 'vue-i18n'

import './assets/simple-sidebar.css'
import './assets/main.css'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
// import '@fortawesome/fontawesome-free/js/all';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/css/v4-shims.min.css'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import toastr from 'toastr';
import 'toastr/toastr.scss';

toastr.options = {
    "closeButton": true,
    "debug": false,
    "progressBar": true,
    "preventDuplicates": false,
    "positionClass": "toast-top-right",
    "showDuration": "400",
    "hideDuration": "1000",
    "timeOut": "7000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};

Vue.use(VueI18n);

import pt from './i18n/pt'

const messages = {
    pt: pt,
};
export const i18n = new VueI18n({
    locale: 'pt', // set locale
    fallbackLocale: 'pt',
    messages, // set locale messages
})

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

Vue.config.productionTip = false;

import App from "./App.vue";
if(document.getElementById("app")) {
    new Vue({
        i18n,
        router,
        store,
        render: h => h(App)
    }).$mount("#app");
}

import Join from "./Join.vue";
if(document.getElementById("joinContainer")) {
    new Vue({
        i18n,
        render: h => h(Join)
    }).$mount("#joinContainer");
}
