<template>
    <div class="row align-middle">
        <div class="col-md-8 col-sm-10 mx-auto mt-5">
            <div class="card">
                <div class="card-header">
                    <div class="text-center">
                        <img class="img-fluid" src='/assets/images/logo-medium.jpg'/>
                    </div>
                </div>

                <div class="card-body">
                    <h2>Homepage</h2>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "Home",
        components: {}
    };
</script>
