import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: () =>
      import(/* webpackChunkName: "change-password" */ "../views/ChangePassword.vue")
  },
  {
    path: "/user-profile",
    name: "UserProfile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/UserProfile.vue")
  },
  {
    path: "/users",
    name: "Users",
    component: () =>
      import(/* webpackChunkName: "user-list" */ "../views/users/UserList.vue")
  },
  {
    path: "/perfis",
    name: "Perfis",
    component: () =>
      import(/* webpackChunkName: "user-list" */ "../views/users/PerfisList.vue")
  },
  {
    path: "/criar-perfil",
    name: "PerfilCriar",
    component: () => import(/* webpackChunkName: "users" */ "../views/users/PerfilCriar.vue"),
  },
  {
    path: "/editar-perfil",
    name: "PerfilEditar",
    props: true,
    component: () => import(/* webpackChunkName: "users" */ "../views/users/PerfilEditar.vue"),
  },
  {
    path: "/user-invite",
    name: "UserInvite",
    component: () =>
      import(/* webpackChunkName: "user-invite" */ "../views/users/UserInvite.vue")
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/concessionarios",
    name: "Concessionarios",
    component: () =>
      import(/* webpackChunkName: "concessionarios" */ "../views/viaturas/ConcessionariosList.vue")
  },
  {
    path: "/carregar-viaturas",
    name: "CarregarViaturas",
    component: () =>
      import(/* webpackChunkName: "viaturas" */ "../views/viaturas/CarregarViaturas.vue")
  },
  {
    path: "/carregar-packing-list",
    name: "CarregarPackingList",
    component: () =>
      import(/* webpackChunkName: "viaturas" */ "../views/viaturas/CarregarPackingList.vue")
  },
  {
    path: "/carregar-entradas",
    name: "CarregarEntradas",
    component: () =>
      import(/* webpackChunkName: "viaturas" */ "../views/viaturas/CarregarEntradas.vue")
  },
  {
    path: "/carregar-homologacoes",
    name: "carregarHomologacoes",
    component: () =>
      import(/* webpackChunkName: "viaturas" */ "../views/viaturas/CarregarHomologacoes.vue")
  },
  {
    path: "/carregar-despachos",
    name: "CarregarDespachos",
    component: () =>
      import(/* webpackChunkName: "despachos" */ "../views/viaturas/CarregarDespachos.vue")
  },
  {
    path: "/stock",
    name: "Stock",
    component: () =>
      import(/* webpackChunkName: "stock" */ "../views/viaturas/Stock.vue")
  },
  {
    path: "/stock-mensal",
    name: "StockMensal",
    component: () =>
      import(/* webpackChunkName: "stock" */ "../views/viaturas/StockMensal.vue")
  },
  {
    path: "/stock/viatura/:id/detalhe",
    name: "ViaturaDetalhe",
    component: () =>
      import(/* webpackChunkName: "viaturas" */ "../views/viaturas/ViaturaDetalhe.vue"),
    props: true
  },
  {
    path: "/pedidos-transporte",
    name: "PedidosTransporte",
    component: () =>
      import(/* webpackChunkName: "pedidos" */ "../views/viaturas/PedidosTransporte.vue"),
  },
  {
    path: "/pedidos-trabalho",
    name: "PedidosTrabalho",
    component: () =>
      import(/* webpackChunkName: "pedidos" */ "../views/viaturas/PedidosTrabalho.vue"),
  },
];

const router = new VueRouter({
  routes
});

export default router;
