<template>
    <div id="sidebar-wrapper">
        <ul class="sidebar-nav" v-if="profile">
            <li class="sidebar-brand">
                <h3>
                    <router-link to="/">
                        <img src="/assets/images/logo-medium.jpg" style="max-height: 40px;" />
                    </router-link>
                </h3>
            </li>
            <li :class="{ active: $route.name === 'Home' }">
                <router-link :to="{ name: 'Home' }"><i class="fa fa-home" style="margin-right: 5px"></i> Home</router-link>
            </li>
            <li v-if="hasPermission('UTILIZADORES_GERIR')" :class="{ active: $route.name === 'Users' }">
                <router-link :to="{ name: 'Users' }"><i class="fa fa-users-cog" style="margin-right: 5px"></i> Utilizadores</router-link>
            </li>
            <li v-if="hasPermission('UTILIZADORES_GERIR')" :class="{ active: $route.name === 'Perfis' }">
                <router-link :to="{ name: 'Perfis' }"><i class="fa fa-users-cog" style="margin-right: 5px"></i> Perfis</router-link>
            </li>
            <li v-if="hasPermission('CONCESSIONARIOS_GERIR')" :class="{ active: $route.name === 'Concessionarios' }">
                <router-link :to="{ name: 'Concessionarios' }"><i class="fa fa-users-cog" style="margin-right: 5px"></i>Concessionarios</router-link>
            </li>
            <li v-if="hasPermission('VIATURAS_CARREGAR')" :class="{ active: $route.name === 'CarregarViaturas' }">
                <router-link :to="{ name: 'CarregarViaturas' }"><i class="fa fa-users-cog" style="margin-right: 5px"></i>Carregar Viaturas</router-link>
            </li>
            <li v-if="hasPermission('FICHEIROS_PACKING_LIST_CARREGAR')" :class="{ active: $route.name === 'CarregarPackingList' }">
                <router-link :to="{ name: 'CarregarPackingList' }"><i class="fa fa-users-cog" style="margin-right: 5px"></i>Carregar Packing List</router-link>
            </li>
            <li v-if="hasPermission('FICHEIROS_ENTRADAS_CARREGAR')" :class="{ active: $route.name === 'CarregarEntradas' }">
                <router-link :to="{ name: 'CarregarEntradas' }"><i class="fa fa-users-cog" style="margin-right: 5px"></i>Carregar Entradas</router-link>
            </li>
            <li v-if="hasPermission('STOCK_ALTERAR')" :class="{ active: $route.name === 'CarregarDespachos' }">
                <router-link :to="{ name: 'CarregarDespachos' }"><i class="fa fa-users-cog" style="margin-right: 5px"></i>Carregar Despachos</router-link>
            </li>
            <li v-if="hasPermission('STOCK_ALTERAR')" :class="{ active: $route.name === 'carregarHomologacoes' }">
                <router-link :to="{ name: 'carregarHomologacoes' }"><i class="fa fa-users-cog" style="margin-right: 5px"></i>Carregar Homologações</router-link>
            </li>
<!--            <li v-if="hasPermission('STOCK_ALTERAR')" :class="{ active: $route.name === 'CarregarDespachos' }">
                <router-link :to="{ name: 'CarregarDespachos' }"><i class="fa fa-users-cog" style="margin-right: 5px"></i>Carregar Despachos</router-link>
            </li>-->
            <li v-if="hasPermission('STOCK_CONSULTAR')" :class="{ active: $route.name === 'Stock' }">
                <router-link :to="{ name: 'Stock' }"><i class="fa fa-users-cog" style="margin-right: 5px"></i>Stock</router-link>
            </li>
            <li v-if="hasPermission('STOCK_MENSAL_CONSULTAR')" :class="{ active: $route.name === 'StockMensal' }">
                <router-link :to="{ name: 'StockMensal' }"><i class="fa fa-users-cog" style="margin-right: 5px"></i>Stock Mensal</router-link>
            </li>
            <li v-if="hasPermission('STOCK_ALTERAR')" :class="{ active: $route.name === 'PedidosTransporte' }">
                <router-link :to="{ name: 'PedidosTransporte' }"><i class="fa fa-users-cog" style="margin-right: 5px"></i>Pedidos Transporte</router-link>
            </li>
            <li v-if="hasPermission('STOCK_ALTERAR')" :class="{ active: $route.name === 'PedidosTrabalho' }">
                <router-link :to="{ name: 'PedidosTrabalho' }"><i class="fa fa-users-cog" style="margin-right: 5px"></i>Pedidos Trabalho</router-link>
            </li>
<!--            <li :class="{ active: $route.name === 'About' }">
                <router-link :to="{ name: 'About' }"><i class="fa fa-user" style="margin-right: 5px"></i> About</router-link>
            </li>-->
        </ul>
    </div>
</template>
<script>
// import {getLanguageOptions} from "../../language-utils";
// import {i18n} from "../../main"
import { mapGetters } from "vuex";

export default {
    components: {},
    data() {
        return {
            // selectedMenu: '',
            // selected: i18n.locale,
            // languageOptions: getLanguageOptions(),
            // showAgencyMenu: false
        };
    },
    watch: {},
    mounted() {
        // ambas estas vars devem estar no main.scala.html
    },
    beforeDestroy() {},
    computed: {
        ...mapGetters({
            profile: "profile",
            isAdmin: "isAdmin",
        }),
    },
    methods: {
        hasPermission(perm) {
            if (this.profile && this.profile.permissions) {
                const result = this.profile.permissions.includes(perm);
                return result;
            } else {
                return true;
            }
        },
        hasPermissionMatchStart(perm) {
            if (this.profile && this.profile.permissions) {
                const result = this.profile.permissions.filter((p) => {
                    return p.startsWith(perm);
                });
                return result.length > 0;
            } else {
                return true;
            }
        },
        hasPermissionMatchEnd(perm) {
            if (this.profile && this.profile.permissions) {
                const result = this.profile.permissions.filter((p) => {
                    return p.endsWith(perm);
                });
                return result.length > 0;
            } else {
                return true;
            }
        },
    },
};
</script>
